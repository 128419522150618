<template>
  <div class="empty-box">
    <div class="empty-cnt">
      <el-image class="empty-img" :src="src" fit="contain"></el-image>
      <div class="empty-dsc">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import emptyImg from "@/assets/img/cup.svg";
export default {
  data() {
    return {
      src: emptyImg,
    };
  },
};
</script>
<style lang="less" scoped>
.empty-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  .empty-cnt {
    width: 200px;
    .empty-img {
      width: 80px;
      height: 80px;
    }
    .empty-dsc {
      font-size: 12px;
      line-height: 20px;
      color: #aaa;
    }
  }
}
</style>
