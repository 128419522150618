<template>
  <div
    class=""
    :style="{ maxWidth: '1600px', margin: '0 auto' }"
  >
    <!-- 轮播 -->
    <el-carousel
      :interval="4000"
      trigger="click"
      arrow="always"
      :type="slideshow.length > 2 ? 'card' : ''"
    >
      <el-carousel-item v-for="(item,index) in slideshow" :key="index">
        <el-image
          :style="{
            width: slideshow.length > 2 && index ? '800px' : '100%',
            height: '100%',
            borderRadius: '10px',
            boxShadow: ' 0px 0px 15px #fff',
          }"
          :src="item.addr"
          fit="cover"
          @click="syy(item)"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: ["slideshow","index"],
  data() {
    return {};
  },
  methods: {
    syy(e) {
      this.$router.push('/scene/sceneInfo?siteId=' + e.siteId)

    }
  },
  created() {},
};
</script>

<style lang="less" scoped>
.el-carousel {
  padding-top: 50px;
  /deep/.el-carousel__container {
    height: 400px;
  }
  /deep/.el-carousel__item {
    overflow: inherit;
  }
  /deep/.el-carousel__item--card.is-active {
    width: 800px;
    // left:50%;
    // transform: translateX(-50%) !important;
    left:calc(25% - 400px);
  }
}
</style>
