// 引入封装的第一层axios接口
import req from "@/utils/req.js"

// 获取轮播图片
export const noticeSlide_api = (params) => {
  return req.get("api/resource-manager/client/picture/notice_page", params)
}
// 获取通知公告列表
export const getNoticeList_api = (params) => {
  return req.get("api/resource-manager/client/notice/page", params)
}
// 获取重要通知公告列表
export const getHeatNoticeList_api = (params) => {
  return req.get("api/resource-manager/client/notice/byCategory",params)
}