<template>
  <div>
    <el-card class="box-card">
      <h4><i class="el-icon-bell"> </i> 重要通知</h4>
      <el-divider></el-divider>
      <div v-if="heatNoticeArr.length > 0">
        <div v-for="(item, j) in heatNoticeArr" :key="j" class="notice-box">
          <div>
            <h5 @click="handleDetail(item)" class="title">{{ item.title }}</h5>
            <p class="date">{{ item.releaseTime }}</p>
          </div>
          <el-divider></el-divider>
        </div>
      </div>
      <EmptyBox v-else >暂无重要通知</EmptyBox>
    </el-card>
  </div>
</template>

<script>
import EmptyBox from "@/components/empty.vue";
import {set} from "@/utils/local.js"
export default {
  props: ["heatNoticeArr"],
  components: {
    EmptyBox,
  },
  data() {
    return {};
  },
  methods: {
    // 查看详情，将数据存本地，详情页直接获取
    handleDetail(item) {
      set("notice",item)
      this.$router.push("/notice/noticeInfo?noticeId="+item.id);
    },
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
.box-card {
  padding-top: 10px;
  h4 {
    font-weight: 700;
    font-size: 16px;
  }
  .notice-box {
    .title {
      font-weight: 600;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: @btn-color;
      }
    }
    .date {
      color: #888;
      font-size: 14px;
      text-align: right;
    }
  }
}
</style>