<template>
  <div class="containar">
    <!-- 轮播 -->
    <Slide :slideshow="slideshow" />
    <div v-if="noticeArr.length > 0" class="main" ref="main">
      <div class="section">
        <h3>全部通知公告</h3>
        <div class="content" v-if="noticeArr.length > 0">
          <div v-for="(item, i) in noticeArr" :key="i" class="item">
            <el-image
              class="img"
              :src="item.img || noticeImg"
              fit="cover"
            ></el-image>
            <div class="text">
              <h4 class="cf">
                <span class="tag fl">通知</span>
                <span class="fl title">{{ item.title }}</span>
                <span class="fr releaseTime">{{ item.releaseTime }}</span>
              </h4>
              <button class="btn" @click="handleDetail(item)">查看详情</button>
            </div>
          </div>
        </div>
        <el-pagination
          v-if="total > pageSize"
          @current-change="changePage"
          background
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="aside">
        <HeatNotice :heatNoticeArr="heatNoticeArr" />
      </div>
    </div>
    <empty-box class="empty-box" v-else>暂无通知</empty-box>
  </div>
</template>

<script>
import Slide from "@/components/slide";
import noticeImg from "@/assets/img/notice.svg";
import HeatNotice from "./components/heatNotice.vue"
import emptyBox from "@/components/empty.vue";
import {set} from "@/utils/local.js"
import { noticeSlide_api, getNoticeList_api,getHeatNoticeList_api } from "@/api/notice.js";
export default {
  components: {
    Slide,
    emptyBox,
    HeatNotice,
  },
  data() {
    return {
      noticeImg: noticeImg,
      slideshow: [],
      noticeArr: [], // 公告数据
      currentPage: 1, // 当前页码
      pageSize: 8, // 每页条数
      total: 0, // 总条数
      heatNoticeArr:[], // 重要公告数据
    };
  },
  methods: {
    // 获取轮播数据
    async getSlideImg() {
      let { data, code } = await noticeSlide_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) this.slideshow = data;
    },
    // 获取通知公告数据
    async getNoticeList() {
      let {code ,data} = await getNoticeList_api({
        tenantId: this.$tenantId,
        size: this.pageSize,
        sort: "create_time",
        sortType: "desc",
        page: this.currentPage,
      });
      if (code == 200){
        this.noticeArr = data.list;
        this.total = data.pageCount
      }
    },
    // 获取重要通知
    async getHeatNoticeList() {
      let {code ,data} = await getHeatNoticeList_api({
        tenantId: this.$tenantId,
        size: 10,
      });
      if (code == 200){
        this.heatNoticeArr = data;
      }
    },

    // 切换页码
    changePage(currentPage) {
      this.currentPage = currentPage;
      document.documentElement.scrollTop = document.body.scrollTop = this.$refs.main.offsetTop;
      this.getNoticeList();
    },
    // 查看详情，将数据存本地，详情页直接获取
    handleDetail(item) {
      set("notice",item)
      this.$router.push("/notice/noticeInfo?noticeId="+item.id);
    },
  },
  created() {
    this.getSlideImg();
    this.getNoticeList();
    this.getHeatNoticeList()
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.main {
  display: flex;
  margin: 100px auto;
  width: 60vw;
  .section {
    flex: 1;
    margin-right:30px;
    h3 {
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 20px;
      text-indent: 2em;
    }
    .content {
      .item {
        padding: 30px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        margin-bottom: 20px;
        .img {
          margin-right: 20px;
          height: 60px;
          width: 100px;
          border-radius: 8px;
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          h4 {
            line-height: 24px;
            width: 100%;
            .tag {
              margin-right: 20px;
              padding: 0 10px;
              background-color: @title-color;
              color: #fff;
              font-size: 12px;
              border-radius: 4px;
              cursor: default;
            }
            .title {
              font-weight: 700;
            }
            .releaseTime {
              font-size: 14px;
              color: #999;
            }
          }
          .btn {
            height: 26px;
            width: 100px;
            border-radius: 13px;
            background-color: @title-color;
          }
        }
      }
    }
    // 分页
    .el-pagination {
      display: flex;
      justify-content: center;
    }
  }
  .aside {
    width: 400px;
  }
}
.empty-box {
  margin: 100px 0;
}
</style>